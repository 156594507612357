import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alert";
import { loading } from "./loading";
import { modal } from "./modal";
import { pass } from "./pass";
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    loading,
    modal,
    pass
  }
});
