<template>
  <div>
    <Loading />
    <nav-bar />

    <router-view style="margin-top:80px; margin-bottom: 80px;" />

    <footer-bar />
    <Modal />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import Loading from "@/components/Loading";
import Modal from "@/components/Modal";

export default {
  components: {
    Loading,
    Modal,
    FooterBar,
    NavBar
  }
};
</script>
<style lang="scss">
.modal.is-active:not(.modal-hero) .modal-background {
  background: hsla(0, 0%, 4%, 0.86) !important;
}
.modal.is-active.modal-hero .modal-content {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  max-width: 75%;
}
.modal.modal-hero .modal-content {
  -webkit-animation: scaleIn 0.5s;
  animation: scaleIn 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>
