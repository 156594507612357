<template>
  <nav
    id="navbar-main"
    class="navbar is-dark is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-item has-text-centered is-block" style="width:100%;">
      <h1 class="is-size-4 has-text-weight-bold has-text-white">
        Kitomer
      </h1>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar"
};
</script>
<style lang="scss" scoped></style>
