import Vue from "vue";

import Buefy from "buefy";
import VueMoment from "vue-moment";
import Vue2Filters from "vue2-filters";
import "./registerServiceWorker";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
// import "./filters";

import "buefy/dist/buefy.css";
import "./scss/main.scss";

Vue.use(Buefy, {
  defaultIconPack: "far"
});
Vue.use(VueMoment);
Vue.use(Vue2Filters);
Vue.config.productionTip = false;

//const app =
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

// if (window.Cypress) {
//   window.app = app;
// }
