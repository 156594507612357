<template>
  <div>
    <div v-if="loaded">
      <section v-if="pass">
        <div class="container px-4">
          <div class="has-text-centered mt-5">
            <qrcode-vue size="200" :value="pass.id"></qrcode-vue>
          </div>
          <div class="mb-2 has-text-centered">
            <strong class="is-size-3">{{ pass.code }}</strong>
          </div>
          <div>
            <p class="mb-4 has-text-centered">
              Display this information at the Gatehouse.
            </p>
          </div>
        </div>
      </section>
      <section v-else>
        <div class="container px-4">
          <div>
            <h3 class="mb-4 is-size-3">
              Uh oh!
            </h3>
            <p>This does not appear to be a valid URL.</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  data() {
    return {
      loaded: false
    };
  },
  methods: {
    ...mapActions({
      lookupPassCode: "pass/lookupPassCode",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    })
  },
  async mounted() {
    this.activateLoading();
    if (this.$route.params.code) {
      if (this.$route.params.code.length === 6) {
        await this.lookupPassCode(this.$route.params.code.toUpperCase());
        this.loaded = true;
        this.deactivateLoading();
      } else {
        await this.getPass(this.$route.params.code);
        this.loaded = true;
        this.deactivateLoading();
      }
    } else {
      this.loaded = true;
      this.deactivateLoading();
    }
  },
  computed: {
    ...mapState({
      pass: (state) => state.pass.item
    })
  }
};
</script>

<style lang="scss" scoped></style>
