import { config } from "../config";
import axios from "axios";
// import QRCode from "qrcode";
const state = {
  item: null
};
// Function URL (reservationCodeLookup(us-central1)): https://us-central1-gateaccess-29471.cloudfunctions.net/reservationCodeLookup
// Function URL (reservationLookup(us-central1)): https://us-central1-gateaccess-29471.cloudfunctions.net/reservationLookup
const mutations = {
  SET_PASS(state, payload) {
    state.item = payload;
  }
};
const actions = {
  async lookupPassCode({ commit }, passCode) {
    try {
      const pass = await axios.get(`${config.api}passCodeLookup`, {
        params: { code: passCode }
      });
      if (pass && pass.status === 200) {
        commit("SET_PASS", {
          ...pass.data.data,
          id: pass.data.id
        });
        return Promise.resolve(pass);
      } else {
        console.log("Error getting pass: ", pass.statusText);
        return Promise.reject();
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject();
    }
  },
  // eslint-disable-next-line
  async lookupPassId({}, passId) {
    try {
      const pass = await axios.get(`${config.api}passIdLookup`, {
        params: { id: passId }
      });
      if (pass && pass.status === 200) {
        return Promise.resolve(pass);
      } else {
        console.log("Error getting pass: ", pass.statusText);
        return Promise.reject();
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject();
    }
  }
};

export const pass = {
  namespaced: true,
  actions,
  mutations,
  state
};
